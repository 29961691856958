import { $ } from "../third-party-imports";
import {paginationMeetTheTeam} from "./paginationMeetTheTeam";

$(function ()
{
    getFranchiseWeblocationIDMeetTheTeam();	 
});

async function getFranchiseWeblocationIDMeetTheTeam(){
    try{     
        if($(".image-card").length>0){
            paginationMeetTheTeam();
        }
        $(".image-wrap").on("click", function(this:any,event:any) {
            let modal = $('#jsEmpBioModal'),
            data = $(this);
            modal.find(".js-emp-name").text(data.find('.js-sub-title').text());
            modal.find(".js-emp-image").attr("src", data.find('.js-img-src').attr('src'));
            modal.find(".js-emp-designation").text(data.find('.js-designation-txt').text());
            modal.find(".js-emp-bio").html(data.attr('data-bio'));
        });	 
        $('html, body').animate({
            scrollTop: $(".team-grid").offset().top
        }, 500);   
    }catch (error: any)
    {
    console.error(`Error in fetching Meet the Team api response Error message: ${error}`);
    }
}