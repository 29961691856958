import { checkIsfakesubmit } from "../services/LeadConfirmation";
import { startLoader, stopLoader } from "../util";
import axios from "axios";
export function bookingApiConfirmationScreen(
    payload: any,
    url: any,
    successHandler: any,
    errHandler: any
) {
    startLoader();
    let formdata = JSON.stringify(payload);
    let config = {
        method: "post",
        url: url,
        headers: {
            "Content-Type": "application/json",
        },
        data: formdata,
    };
    if(!checkIsfakesubmit(config, payload.FirstName)){
        successHandler(payload); 
        console.log("plg-payload",payload)
        return; // Skip execution of the code below
    }
    axios(config)
        .then(function (data) {
            sessionStorage.setItem('rl-post-data',JSON.stringify(payload));
            stopLoader();
            sessionStorage.setItem('rl-post-data',JSON.stringify(payload));
            let apiData = '{ "Result": '+ JSON.stringify(data) +', "Form data": '+ formdata +', "Response message":"SUCCESS" }';
            sessionStorage.setItem('NEI-API data',apiData);
            successHandler(payload);
        })
        .catch(function (error) {
            stopLoader();
            errHandler(error);
        });
}
